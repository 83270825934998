import './Login.css';
import React, { useEffect, useState } from 'react';
// import LoginWithGoogle from './LoginWithGoogle';
import { Fade } from '@mui/material';
import LoginWithOauth from './LoginWithOauth';

function SsoLogin({ }) {

    // const navigate = useNavigate();
    const [fadeLogin, setFadeLogin] = useState(true);

    useEffect(() => {
        const user = localStorage.getItem('loginInfo')
        if (user) {
            // navigate("/");
        }
    }, [])

    return (

        <Fade in={fadeLogin} timeout={500}>
            <div className='h-full flex'>
                <div className='log-in-container'>
                    <LoginWithOauth />
                </div>
            </div>
        </Fade>
    );
}

export default SsoLogin;
