import React from 'react'

const UploadedDoc = ({ fileName, size, submissionUrl }) => {

    function formatFileSize(bytes) {
        if (bytes >= 1000000) {
            return `${(bytes / 1000000).toFixed(1)} MB`;
        } else if (bytes >= 12000) {
            return `${(bytes / 1000).toFixed(0)} KB`;
        } else {
            return `${(bytes / 1000).toFixed(1)} KB`;
        }
    }

    function truncateFileName(name) {
        return name?.length > 45 ? `${name?.slice(0, 45)}...` : name;
    }

    return (
        <div className='bg-[#F4F4F4] p-2 px-3 w-full rounded-md '>
            <div className='text-black font-semibold py-2 text-lg'> Submission Files </div>
            <div
                onClick={() => {
                    window.open(submissionUrl, '_blank');  // opens link in a new tab
                }}
                className='bg-[#E5E5EB] flex items-center p-2 rounded-md cursor-pointer'>
                <div className='opacity-[0.5] font-semibold text-sm text-[#050519]'>
                    {truncateFileName(fileName)}
                </div>
                <div className='ml-auto text-xs font-semibold opacity-[0.9]'>
                    100% Uploaded • {formatFileSize(size)}
                </div>
            </div>
        </div>
    )
}

export default UploadedDoc