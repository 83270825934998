import { faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ReactComponent as Profile_Picture } from '../../Assets/Profile_Picture.svg'
import '../../Common/Common.css'
import { Controls, Player } from '@lottiefiles/react-lottie-player'
import points_assign_background from '../../Lotties/points_assign_background.json';

const AssignedPointsView = ({ name, points }) => {
    return (
        <div className='w-full bg-[#050519] h-[60px] rounded-md items-center flex px-4 my-2'>
            <div className='overflow-hidden h-[60px] absolute z-0'>
                <Player
                    autoplay
                    loop
                    src={points_assign_background}
                    style={{ width: '100%' }}
                >
                    <Controls buttons={['repeat', 'frame', 'debug']} />
                </Player>
            </div>
            {/* <div className='rounded-md bg-[#F5F5FF] px-2 py-2 cursor-pointer flex items-center justify-center w-fit'>
                <FontAwesomeIcon icon={faUser} />
            </div> */}
            <Profile_Picture />
            <div className='ml-3'>
                <div className='text-[#F5F5FF] font-medium opacity-[0.5] text-xs'>Reward Points</div>
                <div className='text-[#F5F5FF] font-medium text-sm'>{name}</div>
            </div>
            <div className='assigned-points text-[#F5F5FF] font-medium text-lg ml-auto z-10'>
                {points}
            </div>
        </div>
    )
}

export default AssignedPointsView