import React from 'react'

const VideoSummary = ({ handleVideoSeek, summary, video }) => {
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60

        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        return formattedTime;
    }

    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md '>
            <div className='text-black font-semibold py-2 text-lg'> Summary </div>
            {
                summary?.summary?.topics?.length > 0 ?
                    <>
                        {summary?.summary?.topics?.map((t, i) => (
                            <div className='text-black font-semibold mb-3' key={i}>
                                <div className='flex items-center gap-2 '>
                                    <span className='text-black font-bold text-lg'>• </span>
                                    <div className='text-[#3838F1] text-[14px] cursor-pointer' onClick={() => {
                                        if (t?.start_time) {
                                            handleVideoSeek(t?.start_time, video)
                                        }
                                    }}>
                                        {formatTime(t?.start_time)}
                                    </div>
                                    <div className=' text-[15px]'>
                                        {t?.topic}
                                    </div>
                                </div>
                                <div className='opacity-[0.7] text-[14.5px] ml-3'>
                                    <div>
                                        {t?.explanation}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div>
                            <div className='text-black font-semibold pt-2 pb-1 text-[17px]'> Points to remember: </div>
                        </div>
                        {
                            summary?.summary?.points_to_remember?.map((point, i) => (

                                <div className=''>
                                    <div>
                                        <span className='opacity-[0.7] text-[14.5px] font-semibold ml-2'>•  {point}</span>
                                    </div>
                                </div>
                            ))
                        }

                    </>
                    :
                    <div className='text-black font-semibold text-[14px] opacity-[0.7]'>
                        This video is no summary
                    </div>
            }
        </div>
    )
}

export default VideoSummary