import React, { useContext, useState } from 'react';
import '../../Common/Common.css';
import BatchEventDefault from '../../Assets/Batch_Event_Default.jpeg'
import { Button, ToggleButtonGroup } from '@mui/joy';

const BatchEvent = ({ setShowBatchEventModal, upcomingBatchEvents, pastBatchEvents }) => {

    const [selectedTab, setSelectedTab] = useState('upcoming')

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${formattedHours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
        return `${formattedTime}`;
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
        return `${formattedDate}`;
    };

    const generateGoogleCalendarLink = (event) => {
        const { name, start_time, end_time, instructor_name, meeting_url } = event;
        const startDateTime = new Date(start_time).toISOString().replace(/-|:|\.\d+/g, '');
        const endDateTime = new Date(end_time).toISOString().replace(/-|:|\.\d+/g, '');

        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(name)}&dates=${startDateTime}/${endDateTime}&details=${encodeURIComponent(`Instructor: ${instructor_name}\nMeeting URL: ${meeting_url}`)}&location=${encodeURIComponent(meeting_url)}&sf=true&output=xml`;
    };

    return (
        <div className='custom-font flex flex-col'>
            <div className='grid gap-3 mb-2'>
                <h2 className='text-[#050519] font-semibold text-2xl'>Events</h2>
            </div>
            <div className='flex justify-center w-full mb-3'>
                <div className="w-fit flex gap-5 px-2 mb-1">
                    <button
                        className={`text-[#050519] uppercase text-[14px] font-semibold flex items-center gap-1 py-1`}
                        style={{ borderBottom: `${selectedTab === "past" ? " 1px solid #050519" : ""}` }}
                        onClick={() => {
                            setSelectedTab('past')
                        }}
                    >
                        Past
                    </button>

                    <button
                        className='text-[#050519] uppercase text-[14px] font-semibold flex items-center gap-1  py-1 '
                        style={{ borderBottom: `${selectedTab === "upcoming" ? "1px solid #050519" : ""}` }}
                        value="references"
                        onClick={() => {
                            setSelectedTab('upcoming')
                        }}
                    >
                        Upcoming
                    </button>

                </div>
            </div>
            <div className={`flex flex-col gap-[8px] ${window.innerWidth <= 768 ? 'h-[310px]' : 'h-[300px]'}  overflow-scroll rounded-[5px]`}>
                {
                    selectedTab === 'past' &&
                    pastBatchEvents?.map((event, i) => (
                        <div className='flex bg-[#f1f1ff] p-1 rounded-[4px] items-center' key={event?.id}>
                            <div className={`${window.innerWidth <= 768 ? 'w-[40px]' : 'w-[50px]'} mx-1 mr-3`}>
                                <img src={BatchEventDefault} alt="" />
                            </div>
                            <div>
                                <div className='font-semibold text-[#6E6E6F] text-[12px]'>
                                    {formatTime(event?.start_time)} - {formatTime(event?.end_time)}, {formatDate(event?.start_time)}
                                </div>
                                <div className='font-semibold text-[#07051B]'>
                                    {event?.name}
                                </div>
                                <div className='font-semibold text-[#6E6E6F] text-[13px]'>
                                    {event?.instructor_name}
                                </div>
                            </div>
                            <div className='ml-auto flex flex-col gap-[8px]'>
                                {
                                    event?.recording_url &&
                                    <button
                                        className={` ${window.innerWidth <= 768 ? 'w-[130px]' : 'w-[150px]'} bg-[#e3a135] text-[#EDEDFF] p-1 rounded-[4px] font-semibold text-[12px]`}
                                        onClick={() => {
                                            let student = localStorage.getItem("student_data")
                                            student = JSON.parse(student)
                                            window.open(event?.recording_url, '_blank')
                                        }}
                                    >
                                        View Event Recording
                                    </button>
                                }
                            </div>
                        </div>
                    ))
                }
                {
                    selectedTab === 'upcoming' &&
                    upcomingBatchEvents?.map((event, i) => (
                        <div className='flex bg-[#f1f1ff] p-1 rounded-[4px] items-center' key={event?.id}>
                            <div className={`${window.innerWidth <= 768 ? 'w-[40px]' : 'w-[50px]'} mx-1 mr-3`}>
                                <img src={BatchEventDefault} alt="" />
                            </div>
                            <div>
                                <div className='font-semibold text-[#6E6E6F] text-[12px]'>
                                    {formatTime(event?.start_time)} - {formatTime(event?.end_time)}, {formatDate(event?.start_time)}
                                </div>
                                <div className='font-semibold text-[#07051B]'>
                                    {event?.name}
                                </div>
                                <div className='font-semibold text-[#6E6E6F] text-[13px]'>
                                    {event?.instructor_name}
                                </div>
                            </div>
                            <div className='ml-auto flex flex-col gap-[8px]'>
                                <button
                                    className={` ${window.innerWidth <= 768 ? 'w-[120px]' : 'w-[150px]'} ${i === 0 ? "bg-[#3838F2]" : "bg-[#BCBBF4] cursor-default"}  text-[#EDEDFF] p-1 rounded-[4px] font-semibold text-xs`}
                                    onClick={() => {
                                        if (i === 0) {
                                            let student = localStorage.getItem("student_data")
                                            student = JSON.parse(student)
                                            window.open(event?.meeting_url, '_blank')
                                        }
                                    }}
                                >
                                    Join {event?.meeting_type === "GOOGLE_MEET" ? "Google Meet" : "Zoom Call"}
                                </button>
                                <a
                                    className={`${window.innerWidth <= 768 ? 'w-[120px]' : 'w-[150px]'} bg-white text--[#565656] p-1 rounded-[4px] font-semibold text-xs text-center`}
                                    href={generateGoogleCalendarLink(event)}
                                    target="_blank"
                                >
                                    Add to Calendar
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className='border-b-[1px] border-[#A7A7A7] mt-2 mb-3 opacity-[0.6] mr-1'></div>
            <button
                className='text-white bg-[#3838F1] rounded-[4px] py-2 px-3 ml-auto mt-auto mr-1'
                onClick={() => setShowBatchEventModal(false)}
            >
                Dismiss
            </button>
        </div>
    );
};

export default BatchEvent;
