import React, { useContext, useState } from 'react';
import '../../Common/Common.css';
import { Input } from '@mui/joy';
import axios from 'axios';
import { API } from '../../Config';
import { CircularProgress } from '@mui/material';
import { GeneralContext } from '../../Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const AccountSetting = ({ setShowAccountSettingModal }) => {

    let student = localStorage.getItem("student_data")
    student = JSON.parse(student)

    const [name, setName] = useState(student?.name)
    const [email, setEmail] = useState(student?.email)
    const [currentPassword, setCurrentPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [loading, setLoading] = useState(false)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [showCurrentPasswordErrorMessage, setShowCurrentPasswordErrorMessage] = useState(false)
    const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] = useState()
    const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false)
    const [passwordErrorMessage, setPasswordErrorMessage] = useState()

    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const { setStudentInfo } = useContext(GeneralContext);

    const checkPasswordValidation = () => {
        if (changePassword) {
            if (newPassword === '') {
                setShowPasswordErrorMessage(true)
                setPasswordErrorMessage("Please enter password")
                return 0
            }
            if (newPassword !== confirmPassword) {
                setShowPasswordErrorMessage(true)
                setPasswordErrorMessage("Password do not match")
                return 0
            }
            const passwordRegex = /^(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/;
            if (!passwordRegex.test(newPassword)) {
                setShowPasswordErrorMessage(true)
                setPasswordErrorMessage("Password must be at least 8 characters long and contain at least one number.")
                return 0
            }
        }
        return 1
    }

    const updateStudent = async () => {
        try {

            if (!checkPasswordValidation()) {
                return;
            }

            let body
            if (changePassword) {
                body = {
                    current_password: currentPassword,
                    password: newPassword,
                    name: name,
                }
            }
            else {
                body = {
                    name: name,
                }
            }

            setLoading(true)
            const { data } = await axios.patch(`${API}/student/update-student?student_id=${student?.student_id}`, body)
            setLoading(false)
            setStudentInfo(data?.data[0])
            localStorage.setItem("student_data", JSON.stringify(data?.data[0]))
            setShowAccountSettingModal(false)
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === 'Incorrect current password') {
                    setShowCurrentPasswordErrorMessage(true)
                    setCurrentPasswordErrorMessage("Incorrect current password")
                }
            }
            setLoading(false)
            console.log("Error", e.message)
        }
    }

    return (
        <div className='custom-font flex flex-col'>
            <div className='grid gap-3 mb-3'>
                <h2 className='text-[#050519] font-semibold text-2xl'>Setting</h2>
            </div>
            <div className='flex flex-col gap-[8px] rounded-[5px] mt-2'>
                <div className='font-bold text-[14.5px]'>Your Name</div>
                <div className='common-input-field' style={{ width: '98%', fontWeight: 600 }}>
                    <Input
                        type='name'
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                            // setShowErrorMessage(false)
                        }}
                        // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                        placeholder='Name'
                        disabled={true}
                    />
                    {/* {showErrorMessage && <div className='error-message'>{errorMessage}</div>} */}
                </div>
                <div className='font-bold text-[14.5px] mt-2'>Your Email</div>
                <div className='common-input-field' style={{ width: '98%', fontWeight: 600 }}>
                    <Input
                        type='email'
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value)
                            // setShowErrorMessage(false)
                        }}
                        // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                        placeholder='Email'
                        disabled={true}
                    />
                    {/* {showErrorMessage && <div className='error-message'>{errorMessage}</div>} */}
                </div>
                {
                    changePassword &&
                    <>
                        <div className='font-bold text-[14.5px] mt-2'>Current Password</div>
                        <div className='common-input-field' style={{ width: '98%', fontWeight: 600 }}>
                            <Input
                                type={showCurrentPassword ? 'text' : 'password'}
                                value={currentPassword}
                                sx={{
                                    '&::before': {
                                        display: 'none',
                                    },
                                    '&:focus-within': {
                                        outlineOffset: '2px',
                                    },
                                }}
                                onChange={(e) => {
                                    setCurrentPassword(e.target.value)
                                    setShowCurrentPasswordErrorMessage(false)
                                    setShowPasswordErrorMessage(false)
                                }}
                                // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                                placeholder='Current Password'
                                endDecorator={
                                    <button onClick={() => {
                                        setShowCurrentPassword(!showCurrentPassword)
                                    }}>
                                        {
                                            showCurrentPassword ?
                                                <FontAwesomeIcon icon={faEye} fontSize={15} />
                                                :
                                                <FontAwesomeIcon icon={faEyeSlash} fontSize={15} />
                                        }

                                    </button>
                                }
                            />
                            {showCurrentPasswordErrorMessage && <div className='error-message'>{currentPasswordErrorMessage}</div>}
                        </div>
                        <div className='font-bold text-[14.5px] mt-2'>New Password</div>
                        <div className='common-input-field' style={{ width: '98%', fontWeight: 600 }}>
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                value={newPassword}
                                sx={{
                                    '&::before': {
                                        display: 'none',
                                    },
                                    '&:focus-within': {
                                        outlineOffset: '2px',
                                    },
                                }}
                                onChange={(e) => {
                                    setNewPassword(e.target.value)
                                    setShowCurrentPasswordErrorMessage(false)
                                    setShowPasswordErrorMessage(false)
                                }}
                                // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                                placeholder='New Password'
                                endDecorator={
                                    <button onClick={() => {
                                        setShowPassword(!showPassword)
                                    }}>
                                        {
                                            showPassword ?
                                                <FontAwesomeIcon icon={faEye} fontSize={15} />
                                                :
                                                <FontAwesomeIcon icon={faEyeSlash} fontSize={15} />
                                        }

                                    </button>
                                }
                            />
                            {/* {showErrorMessage && <div className='error-message'>{errorMessage}</div>} */}
                        </div>
                        <div className='font-bold text-[14.5px] mt-2'>Confirm Password</div>
                        <div className='common-input-field' style={{ width: '98%', fontWeight: 600 }}>
                            <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                sx={{
                                    '&::before': {
                                        display: 'none',
                                    },
                                    '&:focus-within': {
                                        outlineOffset: '2px',
                                    },
                                }}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value)
                                    setShowCurrentPasswordErrorMessage(false)
                                    setShowPasswordErrorMessage(false)
                                }}
                                // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                                placeholder='Confirm Password'
                                endDecorator={
                                    <button onClick={() => {
                                        setShowConfirmPassword(!showConfirmPassword)
                                    }}>
                                        {
                                            showConfirmPassword ?
                                                <FontAwesomeIcon icon={faEye} fontSize={15} />
                                                :
                                                <FontAwesomeIcon icon={faEyeSlash} fontSize={15} />
                                        }

                                    </button>
                                }
                            />
                            {showPasswordErrorMessage && <div className='error-message'>{passwordErrorMessage}</div>}
                        </div>
                    </>
                }
            </div>

            {
                !changePassword && <div className='ml-auto text-blue-600 underline text-sm mt-2 mr-2 cursor-pointer' onClick={() => {
                    setChangePassword(true)
                }}>
                    Change Password
                </div>
            }
            <div className='border-b-[1px] border-[#A7A7A7] mt-4 mb-5 opacity-[0.3] mr-1'></div>
            <div className='flex ml-auto gap-1'>
                <button
                    className='text-[#3838F1] rounded-[4px] py-[5px] px-3 mt-auto mr-1 border-[#EDEDFF] border-[2px]'
                    onClick={() => setShowAccountSettingModal(false)}
                >
                    Cancel
                </button>
                {
                    loading ?
                        <button
                            className='text-white bg-[#3838F1] rounded-[4px] py-[4px] px-3 ml-auto mt-auto mr-1 w-[80px] border-[#3838F1] border-[2px]'
                        // onClick={() => updateStudent(false)}
                        >
                            <CircularProgress size={18} color="inherit" />
                        </button>
                        :
                        <button
                            className='text-white bg-[#3838F1] rounded-[4px] py-[5px] px-3 ml-auto mt-auto mr-1 w-[80px] border-[#3838F1] border-[2px]'
                            onClick={() => updateStudent()}
                        >
                            Submit
                        </button>
                }
            </div>
        </div>
    );
};

export default AccountSetting;