import { faVideo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const VideoChat = ({ video }) => {

    function formatDuration(duration) {
        if(duration){
            const [hours, minutes, seconds] = duration?.split(':').map(Number);
            let formattedDuration = '';
            if (hours) {
                formattedDuration += `${hours} hrs `;
            }
            if (minutes) {
                formattedDuration += `${minutes} min`;
            }
            return formattedDuration.trim();
        }
        else{
            return null
        }
    }

    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md flex gap-2'>
            <div className='w-[20%] bg-gray-400 h-[80px] rounded-md items-center flex justify-center'>
                <FontAwesomeIcon icon={faVideo} style={{ height: 30 }} />
            </div>
            <div className='w-[80%]'>
                <div className='flex items-center w-[full]'>
                    <div className='text-black font-semibold py-2 text-lg capitalize'>
                        {video?.title}
                    </div>
                    <div className='ml-auto font-semibold text-xs opacity-[0.7]'>
                        {formatDuration(video?.duration)}
                    </div>
                </div>
                <div className='text-black font-semibold text-sm opacity-[0.7]'>
                    {/* {video?.summary} */}
                </div>
            </div>

        </div>
    )
}

export default VideoChat