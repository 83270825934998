import React, { useContext, useEffect, useState } from 'react'
import './Courses.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as Calendar } from '../../Assets/Calendar.svg'
import { ReactComponent as Time } from '../../Assets/Time.svg'
import axios from 'axios'
import { API } from '../../Config/index'
import { GeneralContext } from '../../Context'
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import moment from 'moment';
import { ReactComponent as Task_Complete_Icon } from '../../Assets/Task_Complete_Icon.svg'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import zipy from "zipyai";

const formatDate = (dateString) => {
    return moment(dateString).format('D MMMM');
};

const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
};

const calculateWeeksDifference = (start, end) => {
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffInMilliseconds = endDate - startDate;
    return Math.round(diffInMilliseconds / oneWeek);
};

const Courses = () => {
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const demoArray = [1, 2, 3]
    const currentDate = getCurrentDate();
    const rawCurrentDate = new Date();
    const navigate = useNavigate()

    const { setSelectedCourse, studentInfo, setStudentInfo, tenantDetails } = useContext(GeneralContext);

    const getCourses = async () => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            if (student?.student_id) {
                setLoading(true)
                const { data } = await axios.get(`${API}/course/student-courses?student_id=${student?.student_id}&tenant_id=${tenantDetails?.tenant_id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setCourses(data?.data)
                setLoading(false)
            }
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            setLoading(false)
            console.log(e.message)
        }
    }

    const callZipy = () => {
        let student = localStorage.getItem("student_data")
        student = JSON.parse(student)

        zipy.identify(student?.email, {
            tenant_id: tenantDetails?.tenant_id,
            name: student?.name,
            email: student?.email,
            mobile_number: student?.mobile_number,
        });
    }

    useEffect(() => {
        if (studentInfo !== undefined && tenantDetails?.tenant_id) {
            getCourses()
        }
    }, [studentInfo, tenantDetails])

    useEffect(() => {
        callZipy()
    }, [])

    return (
        <div className='courses-container'>
            {/* <ReactPlayer
                url="https://vimeo.com/583715912/08c1e486b8"
                // url="https://www.youtube.com/watch?v=udOoKORXlbY&t=756s"
                controls={true}
                width="100%"
                height='300px'
                onEnded={() => {
                    // handleVideoComplete()
                }}
            /> */}
            <div className="head">
                <div className='text-[#2B2B62] text-[14px] opacity-[0.8]'>{currentDate} </div>
                <div className='welcome-text'>Welcome, {studentInfo?.name}! </div>
                <div className='text-[#050519] text-[17px] mt-3'>Check out your course progress and continue learning.</div>
            </div>
            {
                loading ?
                    <div className="courses-wrapper">
                        {
                            demoArray?.map((i) => (
                                <div className='course' key={i}>
                                    <div className="course-name" style={{ fontSize: 13, width: '70%' }}>
                                        <Skeleton />
                                    </div>
                                    <div className='flex items-center gap-3 mt-auto' style={{ fontSize: 10 }}>
                                        <Calendar style={{ color: '#6E6E6F' }} />
                                        <div className='w-[90px]'><Skeleton /></div>
                                    </div>

                                    <div className='text-[#6E6E6F] flex items-center gap-3 mt-2' style={{ fontSize: 10 }}>
                                        <FontAwesomeIcon icon={faHourglass} fontSize={14} />
                                        <div className='w-[90px]'><Skeleton /></div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className="courses-wrapper">
                        {
                            courses?.map((course, i) => (
                                <div
                                    key={i}
                                    className="course"
                                    onClick={() => {
                                        setSelectedCourse(course)
                                        navigate(`batch/${course?.batch_id}/course/${course?.course_id}`)
                                    }}
                                    style={{
                                        // background: `linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%), url(${TestBackground}) lightgray 50% / cover no-repeat`
                                    }}
                                >
                                    <div className="course-name">
                                        {course?.name?.length > 50
                                            ? `${course?.name.substring(0, 50)}...`
                                            : course?.name}
                                    </div>

                                    <div className="text-[#6E6E6F] text-base font-medium my-auto">
                                        {course?.batch_name?.length > 60
                                            ? `${course?.batch_name.substring(0, 60)}...`
                                            : course?.batch_name}
                                    </div>

                                    <div className='text-[#6E6E6F] flex gap-2 items-center text-xs mt-auto'>
                                        <Calendar style={{ color: '#6E6E6F' }} />
                                        {formatDate(course?.start_date)} - {formatDate(course?.end_date)} ({calculateWeeksDifference(course?.start_date, course?.end_date)} weeks)
                                    </div>
                                    {
                                        (new Date(course?.start_date) <= new Date(rawCurrentDate)) ?
                                            course.points !== null && course.points_earned ?
                                                <div className='text-[#6E6E6F] flex gap-2 items-center text-xs mt-2'>
                                                    {
                                                        ((Number(course?.points_earned) / Number(course?.points)) * 100) === 100 ?
                                                            <Task_Complete_Icon />
                                                            :
                                                            <div className='circular-progress-container w-[16px]'>
                                                                <CircularProgressbar
                                                                    value={((Number(course?.points_earned) / Number(course?.points)) * 100)}
                                                                    strokeWidth={50}
                                                                    styles={buildStyles({
                                                                        strokeLinecap: "butt"
                                                                    })}
                                                                />
                                                            </div>
                                                    }
                                                    <div className='text-[#42B25B] font-semibold'>{Math.round((Number(course?.points_earned)) / (Number(course?.points)) * 100)}% Completed</div>
                                                </div>
                                                :
                                                <div className='text-[#6E6E6F] flex gap-2 items-center text-xs mt-2'>
                                                    <FontAwesomeIcon icon={faHourglass} fontSize={14} />
                                                    Started on {formatDate(course?.start_date)}
                                                </div>
                                            :
                                            <div className='text-[#6E6E6F] flex gap-2 items-center text-xs mt-2'>
                                                <FontAwesomeIcon icon={faHourglass} fontSize={14} />
                                                Starting in {calculateWeeksDifference(rawCurrentDate, course?.start_date)} weeks
                                            </div>

                                    }

                                </div>
                            ))
                        }
                    </div>
            }
        </div>
    )
}

export default Courses