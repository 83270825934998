import React from 'react'
import ReactPlayer from 'react-player'

const Introduction = () => {
  return (
    <div>
      <div className='grid gap-3'>
        <h2 className='text-[#050519] font-semibold text-2xl font-sans'>Using Rooman in 1 min</h2>
        <p className='text-[#6E6E6F] text-[14px] font-sans font-semibold'>A few specific courses from Rooman are powered by Interleap platform for AI assisted learning. Here’s a quick view of how to use it to complete your courses.</p>
      </div>
      <div>
        <ReactPlayer
          url="https://vimeo.com/583715912/08c1e486b8"
          controls={true}
          width="100%"
          height='300px'
        />
      </div>
      <div className='flex justify-end border-t-[1px] border-[#A7A7A7] pt-3'>
        <button className=' flex items-center justify-center w-[96px] h-[44px] bg-[#3838F1] text-[#F5F5FF] font-sans text-base font-semibold pt-[14px] pl-3 pb-[14px] pr-3 rounded-md'>Dismiss</button>
      </div>
    </div>
  )
}

export default Introduction