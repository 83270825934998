import { Textarea } from '@mui/joy'
import React from 'react'

const ErrorMessage = ({ error }) => {
    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md '>
            <div className='text-black font-semibold py-2 text-lg'> Error </div>
            <div className='opacity-[0.8]'>
                <Textarea
                    style={{ padding: 0, color: 'red', backgroundColor: '#F5F5FF', border: 'none', boxShadow: 'none', fontSize: '14px', fontWeight: '500', opacity: '0.7' }}
                    disabled
                    value={error}
                />
            </div>
            {/* <div className='text-red-500 font-semibold text-sm opacity-[0.7]'>
                {error}
            </div> */}

        </div>
    )
}

export default ErrorMessage