import React from 'react'
import { useNavigate } from 'react-router-dom'

const UploadedLink = ({ link }) => {
    const navigate = useNavigate()

    return (
        <div className='bg-[#F4F4F4] p-2 px-3 w-full rounded-md '>
            <div className='text-black font-semibold py-2 text-lg'> Submission Link </div>
            <div
            onClick={() => {
                window.open(link, '_blank');  // opens link in a new tab
            }}

            className='bg-[#E5E5EB] flex items-center p-2 rounded-md cursor-pointer'>
                {/* <div className='opacity-[0.5] font-semibold text-sm text-[#050519]'>
                    link
                </div> */}
                <div className='text-sm font-semibold opacity-[0.6]'>
                    {link}
                </div>
            </div>
        </div>
    )
}

export default UploadedLink