import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CourseProvider } from './Context'
import zipy from 'zipyai'
import * as Sentry from "@sentry/react";

zipy.init('ac55bd69')


Sentry.init({
  dsn: "https://6e946bf24d459ba2fcd94b3a16ccc20b@o4508289393885184.ingest.us.sentry.io/4508289482752000",
  integrations: [],
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_PUBLIC_ENVIRONMENT
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CourseProvider>
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </CourseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
