import { Textarea } from '@mui/joy'
import React from 'react'

const TaskExplanation = ({ explanation }) => {
    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md '>
            {/* <div className='text-black font-semibold py-2 text-lg'>  </div> */}
            <div className='text-black font-semibold text-sm opacity-[0.8]'>
                <Textarea
                    style={{ color: 'black', backgroundColor: '#F5F5FF', border: 'none', boxShadow: 'none', fontSize: '14px' }}
                    disabled
                    value={explanation}
                />
            </div>
        </div>
    )
}

export default TaskExplanation