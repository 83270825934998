export const CodingSuggestion = {
    typescript: `// Welcome to TypeScript Programming!\n// Write your TypeScript code below and check the output in the console!\n// Define a function or write code directly to get started.\n// Call your function to see the result.`,
    kotlin: `// Welcome to Kotlin Programming!\n// Write your Kotlin code below and run it to see the output!\n// Define a main function to organize your code.\n// Print a message or perform actions inside the main function.`,
    python: `# Welcome to Python Programming!\n# Write your code below. Don't forget to define your functions and test them!\n# Define a main function to organize your code.\n# Call the main function to execute your program.`,
    java: `// Welcome to Java Programming!\n// Start by writing your classes and methods here!\n// Define a main class to contain your code.\n// Inside the main method, print a message or perform actions.`,
    mysql: `-- Welcome to SQL!\n-- Write your SQL queries below and run them to see the result.\n-- Example: Select data or perform operations on tables.`,
    javascript: `// Welcome to JavaScript Programming!\n// Write your JavaScript code below and test it in the console!\n// Define a function or write code directly to get started.\n// Call your function to see the output.`,
    bash: `# Welcome to Bash Scripting!\n# Write your shell script below and execute it to see the output.\n# Use echo or other commands to display messages or perform tasks.`,
    nodejs: `// Welcome to Node.js Programming!\n// Write your Node.js code below and run it to see the output!\n// You can use the 'console.log' to display messages or use other Node.js modules.\n// Start by requiring necessary modules and running a simple script.`,
    mongodb: `// Welcome to MongoDB!\n// Write your MongoDB queries below and execute them to interact with the database!\n// Use the MongoDB shell or Node.js driver to connect to a MongoDB database.\n// Example: Perform queries like find(), insert(), update() on a collection.`,
    html: `<!-- Welcome to HTML and CSS! -->\n<!-- Write your HTML structure below and use CSS to style it! -->\n<!-- Start with a basic structure like <!DOCTYPE html>, <html>, <head>, and <body>. -->\n<!-- Add CSS to style your elements and make the page look attractive. -->`,
    react: `// Welcome to React Programming!\n// Write your React code below and test it in the browser!\n// Define components and use hooks to manage state and effects.\n// Start with a functional component and use JSX to render UI elements.`,
    php: `<?php\n// Welcome to PHP Programming!\n// Write your PHP code below and execute it in the browser or server!\n// Define functions, process form data, or connect to a database.\n// Start with a simple script like echo or print to display a message.`,
    angular: `// Welcome to Angular Programming!\n// Write your Angular code below and run it in the browser!\n// Define components, services, and modules in your Angular application.\n// Start with a basic component and use Angular CLI to build and serve the app.`
};