import React, { useContext, useState } from 'react';
import '../../Common/Common.css';
import { Input } from '@mui/joy';
import axios from 'axios';
import { API } from '../../Config';
import { CircularProgress } from '@mui/material';
import { GeneralContext } from '../../Context';

const UploadLinkModal = ({ completeUploadLinkTask, selectedTask, setShowUploadLinkModal }) => {

    const [link, setLink] = useState("")
    const [errorMessage, setErrorMessage] = useState()
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    function isUrlAllowed(inputUrl) {
        try {
            let allowedUrls = selectedTask?.allowed_urls
            const url = new URL(inputUrl);
            const hostname = url.hostname;

            if (allowedUrls.length === 1 && allowedUrls[0] === "") {
                return true;
            }

            return selectedTask?.allowed_urls.some((allowed) => hostname.includes(allowed));
        } catch (error) {
            return false;
        }
    }

    const updateStudent = async () => {
        try {
            if (link === "" || link === null) {
                setShowErrorMessage(true)
                setErrorMessage("Please enter URL")
            }
            else if (isUrlAllowed(link)) {
                setShowUploadLinkModal(false)
                completeUploadLinkTask(link)
            }
            else {
                setShowErrorMessage(true)
                setErrorMessage("This URL is not allowed")
            }
        }
        catch (e) {
            console.log("Error", e.message)
        }
    }

    function getAllowedMessage() {
        const allowedUrls = selectedTask?.allowed_urls || [];

        if (allowedUrls.length === 1 && allowedUrls[0] === "") {
            return "You can upload a link of a website.";
        }
        if (allowedUrls.includes("github.com") && allowedUrls.includes("jupyter.org")) {
            return "You can upload a link of GitHub repository or a Jupyter Notebook.";
        }
        if (allowedUrls.includes("github.com")) {
            return "You can upload a link of GitHub repository.";
        }
        if (allowedUrls.includes("jupyter.org")) {
            return "You can upload a link of Jupyter Notebook hosted on jupyter.org.";
        }
        return "Please upload a link from an approved domain.";
    }

    return (
        <div className='custom-font flex flex-col'>
            <div className='grid gap-3 mb-3'>
                <h2 className='text-[#050519] font-semibold text-2xl'>Upload Link</h2>
            </div>
            <div className='flex flex-col gap-[8px] h-[105px] overflow-scroll rounded-[5px] mt-2'>
                <div className='font-bold mt-2 flex items-baseline gap-1'>
                    Link
                    <div className='text-[14px] opacity-[0.7]'>
                        ({getAllowedMessage()})
                    </div>
                </div>
                <div className='common-input-field' style={{ width: '100%', fontWeight: 600 }}>
                    <Input
                        type='link'
                        sx={{
                            '&::before': {
                                display: 'none',
                            },
                            '&:focus-within': {
                                outlineOffset: '2px',
                            },
                        }}
                        value={link}
                        onChange={(e) => {
                            setLink(e.target.value)
                            setShowErrorMessage(false)
                        }}
                        placeholder='URL'
                    />
                    {showErrorMessage && <div className='error-message ml-1'>{errorMessage}</div>}
                </div>
            </div>
            <div className='border-b-[1px] border-[#A7A7A7] mt-3 mb-5 opacity-[0.3] mr-1'></div>
            <div className='flex ml-auto gap-1'>
                <button
                    className='text-[#3838F1] rounded-[4px] py-[5px] px-3 mt-auto mr-1 border-[#EDEDFF] border-[2px]'
                    onClick={() => setShowUploadLinkModal(false)}
                >
                    Cancel
                </button>
                <button
                    className='text-white bg-[#3838F1] rounded-[4px] py-[5px] px-3 ml-auto mt-auto mr-1 w-[80px] border-[#3838F1] border-[2px]'
                    onClick={() => updateStudent()}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default UploadLinkModal;
