import React, { useContext, useEffect, useRef, useState } from 'react'
import AllRoutes from '../AllRoutes'
import './Home.css'
import { ReactComponent as CompanyLogo } from '../Assets/Rooman_Logo_2.svg'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { GeneralContext } from '../Context'
import { Alert, Snackbar } from '@mui/material'
import { PointsIcon } from '../IconsPath'
import { ReactComponent as Profile_Picture } from '../Assets/Profile_Picture.svg'
import { ReactComponent as Event_Time } from '../Assets/Event_Time.svg'
import { ReactComponent as Redirect } from '../Assets/redirect-icon.svg'
import PoweredByInterleap from '../Assets/powered_by_Interleap.svg'
import { Dropdown, Menu, MenuButton, MenuItem, Modal, ModalClose, Sheet } from '@mui/joy';
import { API } from '../Config'
import axios from 'axios'
import GenericAvatar from '../Assets/Avatar_Generic.png'
import UAParser from 'ua-parser-js';
import BatchEvent from '../Components/BatchEvent/BatchEvent'
import AccountSetting from '../Components/AccountSetting/AccountSetting'
import useNetworkStatus from '../useNetworkStatus'
import * as Sentry from "@sentry/react";

const Home = () => {

    const navigate = useNavigate()
    const modalRef = useRef(null);
    const { tenantDetails, setTenantDetails, setStudentInfo, snackbar, openSnackbar, setOpenSnackbar, studentInfo, courseDetails, selectedCourse, setSelectedCourse, showBatchEventModal, setShowBatchEventModal } = useContext(GeneralContext);
    const [batchEvent, setBatchEvent] = useState();
    const [pastBatchEvent, setPastBatchEvent] = useState();
    const [showEventDetails, setShowEventDetails] = useState(false);
    const [showAccountSettingModal, setShowAccountSettingModal] = useState(false);
    const [shownBatchModal, setShownBatchModal] = useState(false);
    const [timeLeft, setTimeLeft] = useState();
    const isOnline = useNetworkStatus();
    const intervalRef = useRef(null);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);

        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();
        const formattedHours = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';

        const formattedTime = `${formattedHours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
        const formattedDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;

        return `${formattedTime} ${formattedDate}`;
    };

    const getDeviceInfo = () => {
        const parser = new UAParser();
        const result = parser.getResult();

        return {
            browser: `${result.browser.name} ${result.browser.version}`,
            os: `${result.os.name} ${result.os.version}`
        }
    };

    const calculateTimeDifference = (targetTime) => {
        // const now = new Date();
        // const target = new Date(targetTime);
        // const difference = target - now;
        const difference = targetTime;

        if (difference > 0) {
            const seconds = Math.floor(difference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 0) {
                return `${days}d`;
            } else if (hours > 0) {
                return `${hours}h`;
            } else if (minutes > 0) {
                return `${minutes}m`;
            } else {
                return `${seconds}s`;
            }
        } else {
            return null;
        }
    };

    const getStudentInfo = async () => {
        try {
            let student = localStorage.getItem("student_data")
            if (!student) {
                navigate('/login')
            }
            student = JSON.parse(student)
            const { data } = await axios.get(`${API}/student?student_id=${student?.student_id}`)
            setStudentInfo(data?.data[0])
        }
        catch (e) {
            console.log("Error", e.message)
        }
    }

    const logoutStudent = async () => {
        try {
            let student = localStorage.getItem("student_data")
            // if (!student) {
            //     navigate('/login')
            // }
            student = JSON.parse(student)
            const deviceInfo = getDeviceInfo()

            const { data } = await axios.post(`${API}/student/logout`, {
                email: student.email,
                device_info: deviceInfo
            })

            localStorage.removeItem('student_data');
            localStorage.removeItem('token');
            navigate('/login')
            setStudentInfo(null)
        }
        catch (e) {
            console.log("Error", e.message)
        }
    }

    const getBatchEvents = async (batch_id) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.get(`${API}/batch-event/upcoming/${batch_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setBatchEvent(data?.data)
            return data?.data

        } catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const getPastBatchEvents = async (batch_id) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.get(`${API}/batch-event/past/${batch_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setPastBatchEvent(data?.data)
            return data?.data

        } catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    localStorage.removeItem('student_data');
                    localStorage.removeItem('token');
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    const getTenantDetails = async (hostName) => {
        try {
            const { data } = await axios.get(`${API}/tenant/tenant-by-host-name?host_name=${hostName}`)
            setTenantDetails(data?.data[0])
        }
        catch (e) {
            console.log(e.message)
        }
    }

    useEffect(() => {
        // getStudentInfo()
        let student = localStorage.getItem("student_data")
        // if (!student) {
        //     navigate('/login')
        // }
        student = JSON.parse(student)
        setStudentInfo(student)
        if (student) {
            Sentry.setUser({
                email: student.email,
                name: student.name,
            });
        }

    }, [])

    useEffect(() => {
        const getEvents = async () => {
            if (window.location.pathname === '/' || window.location.pathname === '') {
                setBatchEvent(null);
                setPastBatchEvent(null);
                setSelectedCourse(null);
            } else {
                if (window.location.pathname.split('/')[2]) {
                    const events = await getBatchEvents(window.location.pathname.split('/')[2]);
                    const pastEvents = await getPastBatchEvents(window.location.pathname.split('/')[2]);

                    if (events?.length > 0) {
                        const startTime = new Date(events[0]?.start_time);

                        if (intervalRef.current) {
                            clearInterval(intervalRef.current);
                        }

                        let test1 = false
                        let test2 = false

                        intervalRef.current = setInterval(() => {
                            const currentTime = new Date();
                            setTimeLeft(startTime - currentTime)
                            const diffInSeconds = Math.floor((startTime - currentTime) / (1000));
                            if (diffInSeconds === 900 && !test1) {
                                test1 = true
                                setShowBatchEventModal(true)
                            }

                            if (diffInSeconds === 0 && !test2) {
                                test2 = true
                                setShowBatchEventModal(true)
                            }

                            if (diffInSeconds < 0) {
                                clearInterval(intervalRef.current);
                                intervalRef.current = null;
                            }
                        }, 1000);
                    }
                }
            }
        };

        getEvents();

        return () => {
            if (intervalRef.current && window.location.pathname !== '/learn') {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
            else{

            }
        };
    }, [selectedCourse, window.location.pathname]);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target) && !document.querySelector('.modal-button').contains(event.target)) {
            setShowEventDetails(false)
        }
    };


    // useEffect(() => {    
    //     let student = localStorage.getItem("student_data");
    //     student = JSON.parse(student);

    //     if (student) {
    //         if (!document.getElementById('freshchat-script')) {
    //             const script = document.createElement('script');
    //             script.src = '//in.fw-cdn.com/32147310/1146689.js';
    //             script.id = 'freshchat-script';
    //             script.async = true;
    //             script.setAttribute('chat', 'true');

    //             script.onload = () => {
    //                 if (window.fcWidget && window.fcWidget.init) {
    //                     window.fcWidget.init();
    //                     window.fcWidget.user.setFirstName(student?.name);
    //                     window.fcWidget.user.setEmail(student?.email);
    //                 }
    //             };

    //             document.head.appendChild(script);
    //         }
    //     } else {
    //         const script = document.getElementById('freshchat-script');
    //         if (script) {
    //             script.remove();
    //         }

    //         if (window.fcWidget && window.fcWidget.destroy) {
    //             window.fcWidget.destroy();
    //         }
    //     }
    // }, [window.location.pathname]);


    useEffect(() => {
        if (showEventDetails) {
            document.addEventListener('mousedown', handleClickOutside);
        }
        else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showEventDetails]);

    useEffect(() => {
        const hostname = window.location.hostname;
        const subdomainPart = hostname.split('.')[0];
        if (subdomainPart === 'localhost' || subdomainPart === 'dev' || subdomainPart === 'staging') {
            getTenantDetails('lxp')
        }
        else {
            getTenantDetails(subdomainPart)
        }
    }, []);

    return (
        <div className='home-container'>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={showBatchEventModal}
                onClose={() => setShowBatchEventModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: window.innerWidth <= 768 ? '95%' : 650,
                        height: 480,
                        borderRadius: 'md',
                        p: window.innerWidth <= 768 ? 1 : 2,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <BatchEvent setShowBatchEventModal={setShowBatchEventModal} upcomingBatchEvents={batchEvent} pastBatchEvents={pastBatchEvent} />
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={showAccountSettingModal}
                onClose={() => setShowAccountSettingModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        width: window.innerWidth <= 768 ? '90%' : 480,
                        height: 'fit-content',
                        borderRadius: 'md',
                        p: 2,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <AccountSetting setShowAccountSettingModal={setShowAccountSettingModal} />
                </Sheet>
            </Modal>
            {/* <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={() => setOpenSnackbar(false)}>
                <Alert
                    onClose={() => setOpenSnackbar(false)}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbar}
                </Alert>
            </Snackbar> */}
            {
                studentInfo && (window.location.pathname.split('/')[1] !== 'login' || window.location.pathname.split('/')[1] !== 'sso-login') &&
                <>
                    <div className="main-header">
                        <div className="company-logo w-[300px] cursor-pointer flex" onClick={() => navigate('/')}>
                            <img src={tenantDetails?.company_logo} style={{ height: 35 }} />
                            {/* <img src={PoweredByInterleap} className='ml-2 w-[145px]' /> */}
                        </div>
                        <div className="flex w-[300px] gap-2 items-center justify-end">
                            {/* <div className='bg-[#E5E5EB] text-[#3838F1] flex items-center font-semibold text-sm rounded-md px-2 gap-1 h-[32px]'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 10 12" fill="none">
                                <path d={PointsIcon} fill="#3838F1" />
                            </svg>
                            {studentInfo?.points} pts
                        </div> */}

                            {
                                (Array.isArray(batchEvent) && batchEvent.length > 0) ?
                                    <div className='modal-button bg-[#e5e5ff] text-[#3838F1] flex items-center font-semibold text-sm rounded-[4px] py-1 px-3 gap-1 ml-auto cursor-pointer'
                                        onClick={() => {
                                            setShowBatchEventModal(!showBatchEventModal)
                                        }}
                                    >
                                        <Event_Time width={20} />
                                        {/* <span className='ml-1'>{batchEvent[0]?.name} starting in {calculateTimeDifference(batchEvent[0]?.start_time)}</span> */}
                                        {
                                            calculateTimeDifference(timeLeft) ?
                                                <span className='ml-1'>{batchEvent[0]?.name} starting in {calculateTimeDifference(timeLeft)}</span>
                                                :
                                                <span className='ml-1'>{batchEvent[0]?.name} started</span>
                                        }
                                    </div>
                                    :
                                    (
                                        (Array.isArray(pastBatchEvent) && pastBatchEvent.length > 0) &&
                                        <div className='modal-button bg-[#e5e5ff] text-[#3838F1] flex items-center font-semibold text-sm rounded-[4px] py-1 px-3 gap-1 ml-auto cursor-pointer'
                                            onClick={() => {
                                                setShowBatchEventModal(!showBatchEventModal)
                                            }}
                                        >
                                            <Event_Time width={20} />
                                            Past Events
                                        </div>
                                    )

                            }

                            <Dropdown>
                                <MenuButton>
                                    <div className='w-[40px]'>
                                        <img src={GenericAvatar} alt="" />
                                    </div>
                                </MenuButton>
                                <Menu placement="bottom-end" sx={{ borderRadius: 8, border: 0, mt: 1 }}>
                                    <MenuItem
                                        onClick={() => setShowAccountSettingModal(!showAccountSettingModal)}
                                    >
                                        <p className='text-sm font-semibold'>Account Setting</p>
                                    </MenuItem>
                                    <MenuItem
                                    // onClick={() => window.location = 'mailto:sahilnainani45226@gmail.com'}
                                    // onClick={() => openGmail()}
                                    >
                                        <p className='text-sm font-semibold'>Give Feedback</p>
                                    </MenuItem>
                                    <MenuItem onClick={async () => {
                                        await logoutStudent()
                                    }}>
                                        <p className='text-red-600 text-sm font-semibold'>Sign Out</p>
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </div>


                    </div>
                    {/* {
                        batchEvent && showEventDetails &&
                        <div
                            className='bg-white rounded-[4px] py-[3px] px-[3px] absolute right-[10px] top-[50px] custom-font flex flex-col gap-2 w-[300px]'
                            ref={modalRef}
                        >
                            <div className='text-[#6E6E6F] font-semibold text-sm px-3 mt-[10px]'>
                                {formatDate(batchEvent?.start_time)}
                            </div>
                            <div className='font-semibold text-sm px-3'>
                                {`Clarifying Call (${batchEvent?.instructor_name})`}
                            </div>
                            <button className='w-full text-[#3838F1] bg-[#EDEDFF] p-2 rounded-[4px] font-semibold text-sm opacity-[0.7]'
                                onClick={() => {
                                    window.open(batchEvent?.meeting_url, '_blank');
                                }}
                            >
                                Join {
                                    batchEvent?.meeting_type === "GOOGLE_MEET" ?
                                        "Google Meet"
                                        :
                                        batchEvent?.meeting_type === "GOOGLE_MEET" ?
                                            "Zoom Call"
                                            :
                                            ""
                                }
                            </button>
                        </div>
                    } */}
                </>
            }

            <div className="home-content">
                {
                    isOnline ?
                        <AllRoutes />
                        :
                        <div className='h-[170px] w-[300px] text-center gap-3 rounded-[10px] flex flex-col bg-white justify-center'>
                            <div className='text-[26px] text-[#050519] font-semibold'>
                                Network Lost!
                            </div>
                            <div className='opacity-[0.7] text-[#050519] font-semibold'>
                                It looks like there's no Network connection. Check your internet and reload the page.
                            </div>
                        </div>
                }
            </div>

            {
                (window.location.pathname.split('/')[1] !== 'login' && window.location.pathname.split('/')[1] !== 'sso-login') &&
                <div className='flex items-center justify-center'>
                    <img src={PoweredByInterleap} className='w-[145px] mb-1' />
                </div>
            }


        </div>
    )
}

export default Home