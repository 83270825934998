import { useState, useEffect } from 'react';

const useNetworkStatus = (intervalCheck = 5000) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Define a function to update the network status
    const updateNetworkStatus = () => {
      setIsOnline(navigator.onLine);
    };

    // Add event listeners for real-time updates
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    // Set up an interval for continuous checking
    const interval = setInterval(() => {
      updateNetworkStatus();
    }, intervalCheck);

    // Clean up event listeners and interval on component unmount
    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
      clearInterval(interval);
    };
  }, [intervalCheck]);

  return isOnline;
};

export default useNetworkStatus;
