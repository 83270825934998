import React from 'react'
import '../../Common/Common.css'
import { Copy_Code } from '../../IconsPath'

const TaskCode = ({ code, language }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(code)
            .then(() => {
                console.log('Code copied to clipboard!');
            })
            .catch(err => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <div className='bg-black text-[#E1E7E8] p-2 px-3 w-full rounded-md h-[240px] code-font task-code '>
            <div className='flex justify-between items-center'>
                <div className='font-semibold py-2 text-xs uppercase'> {language} </div>
                <button
                    onClick={copyToClipboard}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 10 12" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d={Copy_Code} fill="#AEAED4" />
                    </svg>
                </button>
            </div>
            <pre className='text-xs text-[#E1E7E8] code-font overflow-scroll no-scrollbar h-[190px]'>
                <code
                    className='code-font tracking-wider leading-normal'
                    dangerouslySetInnerHTML={{
                        __html: code
                            ?.replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;')
                            .replace(/ /g, '&nbsp;')
                            .replace(/\n/g, '<br>')
                    }}
                />
            </pre>
        </div>
    )
}

export default TaskCode