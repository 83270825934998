import React, { useContext, useEffect, useState, useRef } from 'react';
import { ReactComponent as CompanyLogo2 } from '../../Assets/Rooman_Logo.svg'
import { Button, Input, Modal, ModalClose, Sheet } from '@mui/joy';
import './LoginWithEmail.css'
import '../../Common/Common.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API } from '../../Config';
import { GeneralContext } from '../../Context';
import UAParser from 'ua-parser-js';
import { CircularProgress } from '@mui/material';
import PoweredByInterleap from '../../Assets/powered_by_Interleap_login.svg'
import zipy from "zipyai";

function LoginWithOauth() {

    const navigate = useNavigate()
    const [email, setEmail] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [loading, setLoading] = useState(false);
    const [alreadyLoggedInModal, setAlreadyLoggedInModal] = useState(false);
    const { setStudentInfo } = useContext(GeneralContext);

    const [code, setCode] = useState()
    const { tenantDetails } = useContext(GeneralContext);

    useEffect(() => {
        // Get authorization code from query params
        const params = new URLSearchParams(window.location.search);
        const tempCode = params.get('code');
        const tempEmail = params.get('email');
        setCode(tempCode)
        setEmail(tempEmail)
        exchangeCodeForToken(tempCode, tempEmail)
    }, []);

    // Email Regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const exchangeCodeForToken = async (tempCode, tempEmail) => {
        try {
            setLoading(true)
            const deviceInfo = getDeviceInfo()
            const { data } = await axios.post(`${API}/token`, {
                code: tempCode,
                deviceInfo,
                email: tempEmail
            });

            setErrorMessage()
            setLoading(false)
            setStudentInfo(data?.data?.student_data)

            localStorage.setItem("student_data", JSON.stringify(data?.data?.student_data))
            localStorage.setItem("token", JSON.stringify(data?.data?.token))

            if (data?.data?.student_data) {
                zipy.identify(data?.data?.student_data?.email, {
                    tenant_id: tenantDetails?.tenant_id,
                    name: data?.data?.student_data?.name,
                    email: data?.data?.student_data?.email,
                    mobile_number: data?.data?.student_data?.mobile_number,
                });
            }            

            if (data?.data?.course_id && data?.data?.batch_id) {
                navigate(`/batch/${data?.data?.batch_id}/course/${data?.data?.course_id}`)
            }
            else {
                navigate('/')
            }

        } catch (error) {
            if (error?.response) {
                setShowErrorMessage(true)
                setErrorMessage(error.response?.data?.message)
            }
            setLoading(false)
            navigate('/login')
            console.error('Error exchanging code for token:', error);
        }
    };

    const getDeviceInfo = () => {
        const parser = new UAParser();
        const result = parser.getResult();

        return {
            browser: `${result.browser.name} ${result.browser.version}`,
            os: `${result.os.name} ${result.os.version}`
        }
    };

    const logoutStudent = async () => {
        try {
            setAlreadyLoggedInModal(false)
            let student = localStorage.getItem("student_data")
            student = JSON.parse(student)
            const deviceInfo = getDeviceInfo()

            const { data } = await axios.post(`${API}/student/logout`, {
                email: email,
                device_info: deviceInfo
            })
            setStudentInfo(null)
            // sendOtpToEmail()
        }
        catch (e) {
            console.log("Error", e.message)
        }
    }

    const handleKeyDown = (event, handler) => {
        if (event.key === 'Enter') {
            if (emailRegex.test(email)) {
                handler();
            }
            else {
                setErrorMessage("Please enter a valid email")
                setShowErrorMessage(true)
            }
        }
    }

    useEffect(() => {
        setStudentInfo(null)
        // exchangeCodeForToken()
    }, [])

    return (
        <div className="login-with-email">

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={alreadyLoggedInModal}
                onClose={() => setAlreadyLoggedInModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <div className='fitgree-font text-[#050519] flex flex-col gap-4'>
                        <div className='text-[20px] font-semibold'>Multiple Sessions Detected</div>
                        <div className='text-base font-semibold'>Oops! It looks like your session is active elsewhere. For security reasons, currently only one session is allowed on an account at a time.</div>
                        <div className='text-base font-semibold'>Would you like to continue here or log-out to continue the session elsewhere? Don't worry, your session progress is safe either way!</div>
                        <button
                            className='font-semibold text-[#F5F5FF] bg-[#3838F1] w-fit rounded-md text-sm py-1 px-2 ml-auto'
                            onClick={() => {
                                logoutStudent()
                            }}
                        >
                            Continue Here
                        </button>
                    </div>
                </Sheet>
            </Modal>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="company-name">
                    <img src={tenantDetails?.company_logo} style={{ height: 45 }} />
                </div>
                <img src={PoweredByInterleap} className='mt-2 h-[30px]' />
            </div>

            <div className='content'>
                <div className='mb-5 font-semibold'>Enter You Email ID</div>
                <div className='common-input-field' style={{ width: '100%' }}>
                    <Input
                        type='email'
                        value={email}
                        onChange={(e) => {
                            const lowerCaseEmail = e.target.value.toLowerCase();
                            setEmail(lowerCaseEmail)
                            setShowErrorMessage(false)
                        }}
                        onKeyDown={(e) => handleKeyDown(e, exchangeCodeForToken)}
                        placeholder='Email'
                        disabled={loading}
                    />
                    {showErrorMessage && <div className='error-message'>{errorMessage}</div>}
                </div>

                <div className='mt-[25px] w-[120px]'>
                    {loading ? (
                        <button className='w-full bg-white text-[#050519] rounded font-semibold text-md'>
                            <CircularProgress size={18} color="inherit" />
                        </button>
                    ) : (
                        <button
                            className='w-full bg-white text-[#050519] rounded font-semibold text-base p-[7px]'
                            onClick={() => {
                                if (emailRegex.test(email)) {
                                    exchangeCodeForToken()
                                }
                                else {
                                    setErrorMessage("Please enter a valid email")
                                    setShowErrorMessage(true)
                                }
                            }}
                        >Login</button>
                    )}
                </div>
            </div>

        </div>
    );
}

export default LoginWithOauth;